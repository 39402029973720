const UPCOMING_RANTANGAN = [];
export default UPCOMING_RANTANGAN;

// {
//	image: rantangan,
//	title: 'title',
//	description: 'desc',
//  loc:'loc',
//  price: '$10',
//  registrationLink:'',
// },
